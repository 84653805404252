@tailwind base;
@tailwind components;
@tailwind utilities;
.link-underline {
    border-bottom-width: 0;
    background-image: linear-gradient(transparent, transparent), linear-gradient(#fff, #fff);
    background-size: 0 3px;
    background-position: 0 100%;
    background-repeat: no-repeat;
    transition: background-size .3s ease-in-out;
}

.link-underline-black {
    background-image: linear-gradient(transparent, transparent), linear-gradient(#4F46E5, #6F4DFF)
}

.link-underline:hover {
    background-size: 100% 3px;
    background-position: 0 100%
}

.dropdown:hover .dropdown-menu {
    display: block;
}